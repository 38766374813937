import React, { Component } from "react"
import "./style.scss"
import BackPage from "@components/_molecules/back_parent_dashboard"
import FormButton from "@components/_atoms/button_form_input_submit"
import InputLabelState from "@components/_molecules/input_label_state"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import ResponseMessage from "@components/_atoms/response_message"
import axios from "axios"
import * as headers from "@data/headers"
import * as errors from "@services/errors"
import { validate } from "@data/validations"

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentpassword: "",
      curPasswordErrorStatus: "",
      newpassword: "",
      confirmpassword: "",
      newPasswordErrorStatus: "",
      confPasswordErrorStatus: "",
      buttonSubmitted: false,
      generalError: null,
      passwordSuccess: false
    }
  }

  handleChange = ev => {
    this.setState({
      [ev.target.name]: ev.target.value,
      [`${ev.target.name}ErrorStatus`]: false,
      generalError: null
    })
  }

  handleSubmit = ev => {
    ev.preventDefault()
    let _this = this //needed to avoid losing context during axios/fetch call
    let curPasswordErrorStatus = !validate.password.test(
        this.state.currentpassword
      ),
      newPasswordErrorStatus = !validate.password.test(this.state.newpassword),
      confPasswordErrorStatus =
        this.state.newpassword !== this.state.confirmpassword
    _this.setState({
      curPasswordErrorStatus,
      newPasswordErrorStatus,
      confPasswordErrorStatus
    })
    if (
      !curPasswordErrorStatus &&
      !newPasswordErrorStatus &&
      !confPasswordErrorStatus
    ) {
      _this.setState({ buttonSubmitted: true })

      let data = {
        current_password: this.state.currentpassword,
        new_password: this.state.newpassword,
        new_password_confirmation: this.state.confirmpassword
      }

      const options = {
        method: "PATCH",
        data: JSON.stringify(data),
        headers: headers.getHeaders(),
        url: `${process.env.API_HOST}v1/user/update_password`
      }
      axios(options)
        .then(res => {
          this.setState({
            buttonSubmitted: false,
            passwordSuccess: true,
            currentpassword: "",
            newpassword: "",
            confirmpassword: ""
          })
        })
        .catch(error => {
          console.log(error.response)
          this.setState({
            buttonSubmitted: false,
            generalError: error.response.data.errors[0].message
          })
        })
    }
  }

  render() {
    return (
      <div className="change-password-component">
        <div className="heading-size">
          <BackPage
            data={{
              title: "Change Password",
              link: "/app",
              heading: {
                pageTitle: "Change Password",
                link: "/app"
              }
            }}
          />
        </div>
        {this.state.passwordSuccess && (
          <ResponseMessage>Successfully updated password</ResponseMessage>
        )}
        <div className="container-app main-contain">
          <p className="title">Account Info</p>
          <form className="change-password-form" onSubmit={this.handleSubmit}>
            <div className="input-wrap">
              <InputLabelState
                data={{
                  name: `Current Password`,
                  type: `password`,
                  helpText:
                    "Min 8 characters, 1 uppercase, 1 lowercase, 1 number",
                  errorStatus: this.state.curPasswordErrorStatus,
                  defaultVisibility: false,
                  value: this.state.currentpassword
                }}
                onChange={this.handleChange.bind(this)}
              />
              <InputLabelState
                data={{
                  name: `New Password`,
                  type: `password`,
                  helpText:
                    "Min 8 characters, 1 uppercase, 1 lowercase, 1 number",
                  errorStatus: this.state.newPasswordErrorStatus,
                  defaultVisibility: 1,
                  value: this.state.newpassword
                }}
                onChange={this.handleChange.bind(this)}
              />
              <InputLabelState
                data={{
                  name: `Confirm Password`,
                  type: `password`,
                  helpText: "Passwords must match",
                  errorStatus: this.state.confPasswordErrorStatus,
                  defaultVisibility: 0,
                  value: this.state.confirmpassword
                }}
                onChange={this.handleChange.bind(this)}
              />
            </div>
            <div style={{ width: `100%`, height: `3rem` }}></div>
            <GeneralSubmitMessage error={this.state.generalError} />
            <FormButton
              data={{
                text: "Update Password"
              }}
              isDisabled={this.state.buttonSubmitted}
            />
          </form>
        </div>
      </div>
    )
  }
}
export default ChangePasswordForm
