import React from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import ChangePasswordForm from "@components/_molecules/form_change_password"

const PasswordResetPage = () => {
  return (
    <Layout type="app">
      <SEO title="Change Password" />
      <section className="standard">
        <ChangePasswordForm />
      </section>
    </Layout>
  )
}

export default PasswordResetPage
