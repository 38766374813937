import React from "react"
import "./style.scss"
import { Link } from "gatsby"

const BackPage = ({ data }) => {
  return (
    <div className="back-page">
      <Link
        to={data.link}
        className={
          data.pageTitle === "title-2a" ? "home-page" : "page-title title-2a"
        }
      >
        {data.pageTitle}
      </Link>
    </div>
  )
}

export default BackPage
